import axios from 'axios'
import React, { createContext, useState, useEffect, useReducer } from 'react'
import { ACTIONS } from './actions'
import AppReducer from './AppReducer'
import qs from 'querystring'

/* Initial State */
const initialState = {
    zipData: [],
    zipDataLoading: false,
    errors: [],
    zipCode: '',
    isRiskArea: undefined,
    showResult: false
}

/* URL Params for Customizations */
let params = '';

if (window.location.search) {
    try {
        params = atob(window.location.search.slice(1))
    } catch (err) {
        console.error('URL Params are invalid. Decryption failed. Custom Styles could not be applied.')
    }
}

const decodedParams = qs.decode(decodeURIComponent(params))

const initialOptions = {
    /* Wrapper */
    verticalPadding: decodedParams.verticalPadding || '2rem',
    horizontalPadding: decodedParams.horizontalPadding || '2rem',

    /* Buttons */
    buttonPosition: decodedParams.buttonPosition || 'center',
    buttonColor: decodedParams.buttonColor || '#303030',
    buttonRadius: decodedParams.buttonRadius || '999px',

    /* Input */
    inputFontSize: decodedParams.inputFontSize || '1.5rem',
    inputBorderRadius: decodedParams.inputBorderRadius || '5px',
    inputVerticalPadding: decodedParams.inputVerticalPadding || '1rem',
    inputHorizontalPadding: decodedParams.inputHorizontalPadding || '2rem',
}

/* Context */
export const GlobalContext = createContext(initialState)

/* Provider */
export const GlobalProvider = ({ children }) => {
    const [state, dispatch] = useReducer(AppReducer, initialState)
    const [options, setOptions] = useState(initialOptions)


    useEffect(() => {
        (async function () {
            try {
                const res = await axios.get(process.env.REACT_APP_API_URL)
                dispatch({
                    type: ACTIONS.FETCHED_ZIP_DATA,
                    payload: res.data
                })
            } catch (err) {
                dispatch({
                    type: ACTIONS.SET_ERRORS,
                    payload: err
                })
            }
        })()
    }, [])

    /* Actions */
    const fetchZipData = async () => {

        dispatch({
            type: ACTIONS.START_FETCHING_ZIP_DATA
        })

        const res = await axios.get(process.env.REACT_APP_API_URL)

        dispatch({
            type: ACTIONS.FETCHED_ZIP_DATA,
            payload: res.data
        })

    }

    function setErrors(err) {
        dispatch({
            type: ACTIONS.SET_ERRORS,
            payload: err
        })
    }

    function setZipCode(status) {
        dispatch({
            type: ACTIONS.SET_ZIP_CODE,
            payload: status
        })
    }

    function setRiskArea(status) {
        dispatch({
            type: ACTIONS.SET_RISK_AREA,
            payload: status
        })
    }

    function setShowResult(status) {
        dispatch({
            type: ACTIONS.SET_SHOW_RESULT,
            payload: status
        })
    }

    return (
        <GlobalContext.Provider value={{
            zipData: state.zipData, fetchZipData,
            zipDataLoading: state.zipDataLoading,
            errors: state.errors, setErrors,
            zipCode: state.zipCode, setZipCode,
            isRiskArea: state.isRiskArea, setRiskArea,
            showResult: state.showResult, setShowResult,
            options,
            setOptions
        }}>
            {children}
        </GlobalContext.Provider>
    )
}