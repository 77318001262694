import React, { useContext } from 'react'
import { GlobalContext } from '../../context/GlobalState'
import styles from './input.module.scss'

export const ZipInput = (props) => {

    const { zipCode, setZipCode, setShowResult, options } = useContext(GlobalContext)

    const handleChange = (e) => {
        setZipCode(e.target.value)
        setShowResult(false)
    }

    return (
        <input
            className={styles.input}
            type="number"
            pattern="[0-9]*"
            value={zipCode}
            placeholder={props.placeholder}
            onChange={handleChange}
            disabled={props.disabled}
            style={{
                fontSize: options.inputFontSize,
                padding: `${options.inputVerticalPadding} ${options.inputHorizontalPadding}`,
                borderRadius: options.inputBorderRadius
            }}
        />
    )
}