import React, { useContext } from 'react'
import { GlobalContext } from '../../context/GlobalState'
import styles from './wrapper.module.scss'

export const MainWrapper = (props) => {

    const { options } = useContext(GlobalContext)

    return (
        <div className={styles.wrapper} style={{ padding: `${options.verticalPadding} ${options.horizontalPadding}` }}>
            {props.children}
        </div>
    )
}