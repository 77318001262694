import React from 'react';
import './App.scss';
import { initAnalytics } from './helpers/initAnalytics';
import { Checker } from './components/Checker';
import { MainWrapper } from './components/MainWrapper';
import { GlobalProvider } from './context/GlobalState';

initAnalytics()

function App() {
  return (
    <GlobalProvider>
      <MainWrapper>
        <Checker />
      </MainWrapper>
    </GlobalProvider>
  );
}

export default App;