import React, { useContext } from 'react'
import { GlobalContext } from '../../context/GlobalState'
import styles from './button.module.scss'

export const Button = (props) => {

    const { options } = useContext(GlobalContext)

    return (
        <button
            className={styles.button}
            onClick={props.onClick}
            disabled={props.disabled}
            style={{
                backgroundColor: options.buttonColor,
                borderRadius: options.buttonRadius
            }}>
            {props.text}
        </button>
    )
}