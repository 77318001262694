import { ACTIONS } from "./actions";

export default (state, action) => {
    switch (action.type) {
        case ACTIONS.START_FETCHING_ZIP_DATA:
            return {
                ...state,
                zipDataLoading: true
            }
        case ACTIONS.FETCHED_ZIP_DATA:
            return {
                ...state,
                zipDataLoading: false,
                zipData: action.payload
            }
        case ACTIONS.SET_ERRORS:
            return {
                ...state,
                errors: action.payload
            }
        case ACTIONS.SET_ZIP_CODE:
            return {
                ...state,
                zipCode: action.payload
            }
        case ACTIONS.SET_RISK_AREA:
            return {
                ...state,
                isRiskArea: action.payload
            }
        case ACTIONS.SET_SHOW_RESULT:
            return {
                ...state,
                showResult: action.payload
            }
        default:
            return state;
    }
}